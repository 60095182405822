<template>
  <div class="home">
    <home_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import home_component from '@/components/home_component.vue'

export default {
  name: 'home',
  components: {
    home_component
  }
}
</script>
