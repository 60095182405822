import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)
//you can either import the views to the router like above, or like bellow, directly from the routes object
const routes = [

  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/virtualization',
    name: 'virtualization',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/virtualization.vue')
  },
  {
    path: '/services',
    name: 'services',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/services.vue')
  },
  {
    path: '/cloudflare-pages',
    name: 'cloudflare-pages',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/cloudflare-pages.vue')
  },
  {
    path: '/hosting',
    name: 'hosting',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/hosting.vue')
  },
  {
    path: '/online-shop',
    name: 'online-shop',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/online-shop.vue')
  },
  {
    path: '/server-administration',
    name: 'server-administration',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/server-administration.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/contact.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/about.vue')
  },

  {
    path: '/legal/privacy-policy',
    name: 'privacy-policy',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/legal/privacy-policy.vue')
  },
  {
    path: '/legal/terms&conditions',
    name: 'terms&conditions',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/legal/terms&conditions.vue')
  },
  {
    path: '/legal/cookies',
    name: 'cookies',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/legal/cookies.vue')
  },
  {
    path: '/success',
    name: 'success',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/success.vue')
  },
  {
    path: '/error',
    name: 'Error',
    // route level code-splitting
    // this generates a separate chunk (virtualization.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virtualization" */ '../views/Error.vue')
  },

  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: () => import('../views/PageNotFound.vue')
  }
]
/* PAGE_NOT_FOUND: this one was
     build without injecting a component into the view.
     components are useful when scoped style is needed amongst other things. you can't write
     css directly into the view. just learned this on 9.12.2021 at 11:00 hour */

const router = new VueRouter({
  routes
})

export default router
