<template>
  <div id="app">
    <div id="arrow" style="display: none" onclick="window.scrollTo(0, 0);">&uArr;</div>
    <router-view/><!-- this is what was missing in order for the router to display the html from the views-->
    <footer class="footer">
      <div class="container text-center">
        <div class="row">
          <div class="col-sm-12 col-lg-3">
            <h3 class="footer-title">FIND US</h3>
            <a class="footer-li special-paragraph" href="https://www.facebook.com/oncloudromania" target="_blank"> <img src="./assets/fb.svg" width="18" height="18" alt="twitter logo"></a>
            <a class="footer-li special-paragraph" href="https://www.linkedin.com/company/28923476/" target="_blank"> <img src="./assets/in.svg" alt="IN logo"></a>
            <a class="footer-li special-paragraph" href="https://twitter.com/vying_tech" target="_blank"> <img src="./assets/Twitter.svg" width="18" height="18" alt="twitter logo"></a>
            <p class="footer-li special-paragraph">Built for learning.</p>
            <p class="footer-li special-paragraph">Deployed as startup.</p>
            <p class="footer-li special-paragraph">©2022 Vying Technologies OU.</p>
          </div>
          <div class="col-sm-12 col-lg-3">
            <p class="footer-title">Oncloud</p>
            <ul style="padding-left: 0">
              <li class="footer-li"><router-link to="/" onclick="window.scrollTo(0, 0);">Home</router-link></li>
              <li class="footer-li"><router-link to="/about" onclick="window.scrollTo(0, 0);">About us</router-link></li>
              <li class="footer-li"><router-link to="/contact" onclick="window.scrollTo(0, 0);">Contact</router-link></li>
              <li class="footer-li"><a href="https://vying.io/checkout" class="footer-li"  target="_blank">Pay by debit/credit card</a></li>
            </ul>
          </div>
          <div class="col-sm-12 col-lg-3">
            <p class="footer-title">Legal</p>
            <ul style="padding-left: 0">
              <li class="footer-li"><router-link to="/legal/terms&conditions" onclick="window.scrollTo(0, 0);">Terms and Conditions</router-link></li>
              <li class="footer-li"><router-link to="/legal/privacy-policy" onclick="window.scrollTo(0, 0);">Privacy policy</router-link></li>
              <li class="footer-li"><router-link to="/legal/cookies" onclick="window.scrollTo(0, 0);">Cookies</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
window.scrollTo(0, 0);
</script>

<style>
@font-face {
  font-family: 'SegoeUI';
  src: url("./assets/segoe-ui-4-cufonfonts/Segoe UI.ttf")
}
@font-face {
  font-family: 'SegoeUI Bold';
  src: url("./assets/segoe-ui-4-cufonfonts/Segoe UI Bold.ttf")
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#nav a {
  font-family: "SegoeUI", sans-serif;
  text-decoration: none;
  padding-right: 15px;
  letter-spacing: 3px;
  font-size: 19px;
  color: #2c3e50;
}
#nav a:active {
  font-weight: bold;
  font-family: "SegoeUI", sans-serif;
  text-decoration: none;
  padding-right: 15px;
  letter-spacing: 3px;
  font-size: 19px;
  color: white;
}
#nav a:visited {
  font-weight: bold;
  color: black;
  font-family: "SegoeUI", sans-serif;
  text-decoration: none;
  padding-right: 15px;
  letter-spacing: 3px;
  font-size: 19px;
}

footer {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: rgb(236, 239, 247);
}
.footer-title {
  font-family: SegoeUI, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #556a70;
  font-weight: 800;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 30px;
  margin-top: 50px;
}
.footer-li {
  font-family: SegoeUI, sans-serif;
  line-height: 30px;
  color: #556a70;
  font-weight: 300;
  letter-spacing: 2px;
  list-style: none;
  margin-left: 0;
  margin-bottom: 10px;
}
.footer-li a {
  text-decoration: none;
  font-family: SegoeUI, sans-serif;
  line-height: 30px;
  color: #556a70;
  font-weight: 300;
  letter-spacing: 2px;
  list-style: none;
  margin-left: 0;
  margin-bottom: 10px;
}
.special-paragraph {
  margin-right: 10px;
  margin-top: 30px;
  font-size: 12px;
  text-indent: 0;
}
@media screen and (max-width: 414px) {
  #arrow {
    display: inline-block!important;
    float: right;
    font-size: 25px;
    background-color: rgba(1,1,1,0.3);
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px 10px 1px 10px;
    position: fixed;
    top: 50%;
    right: 1%;
    z-index: 100;
  }
}
</style>
