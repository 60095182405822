<template>
    <div id="background">
        <b-container fluid id="nav">
        <b-navbar toggleable="lg" id="navbar">
          <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
          <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
            <template #default="{ expanded }">
              <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
              <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
            </template>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items with ms auto not ml auto -->
            <b-navbar-nav class="ms-auto">
              <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
              <b-nav-item-dropdown text="About" right class="nav-item">
                <b-dropdown-item href="cloudflare-pages" class="nav-item"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
                <b-dropdown-item href="virtualization" class="nav-item"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
                <b-dropdown-item href="hosting" class="nav-item"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
                <b-dropdown-item href="online-shop" class="nav-item"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
                <b-dropdown-item href="server-administration" class="nav-item"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
              <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-container>
        <b-container fluid id="home-container">
          <b-row>
            <b-col sm="6" id="landing-text-cols" class="text-center">
               <h1 class="text-center" id="landing-h1">We can build your custom website, or just the front end of it, host it on GitHub, and deploy it worldwide on Cloudflare Pages.</h1>
              <h4 class="text-center" id="landing-h4">Learn more about this type of web development, GitHub and Cloudflare Pages, by clicking on the button bellow.</h4>
              <b-button id="learn-more-button"><router-link to="/cloudflare-pages">Learn more</router-link></b-button>
              </b-col>
            <b-col sm="6" class="text-center">
              <img class="img-fluid" src="../assets/landing-macbook-oncloud.how@2x.png" alt="macbook pro" id="macbook">
              <b-button id="learn-more-button-second" style="display: none"><router-link to="/cloudflare-pages">Learn more</router-link></b-button>

            </b-col>
          </b-row>
        </b-container>

    </div>
</template>

<script>
export default {
  name: 'home_component',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

.nav-item {
  font-family: "SegoeUI", sans-serif;
}
#home-container {
  margin-top: 10%;
}

#landing-text-cols {
  padding-left: 5%;
  padding-right: 5%;
}

#landing-h1 {
  color: #1A1E3E;
  letter-spacing: 4px;
  line-height: 1.8;
}
#landing-h4 {
  color: #1A1E3E;
  letter-spacing: 1px;
  line-height: 1.6;
}
#background{
  background: url("../assets/homebackground/Group 2765@3x.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 110vh;/*if not set above 100vh it gaps on the big screens on the left side*/

}
#learn-more-button {
  margin-top: 5%;
  background-color: transparent;
  color: #1A1E3E;
  text-decoration: none;
  border: 2px solid #1A1E3E;
}

#learn-more-button-second {
  margin-top: 5%;
  background-color: transparent;
  color: #1A1E3E;
  text-decoration: none;
  border: 2px solid #1A1E3E;
}

#learn-more-button a {
  padding-right: 35px!important;
  padding-left: 35px!important;
  letter-spacing: 3px;
  background-color: transparent;
  border-color: #1A1E3E;
  color: #1A1E3E;
  text-decoration: none;
  font-size: 30px;

}
#learn-more-button-second a {
  padding-right: 35px!important;
  padding-left: 35px!important;
  letter-spacing: 3px;
  background-color: transparent;
  border-color: #1A1E3E;
  color: #1A1E3E;
  text-decoration: none;
  font-size: 30px;

}
@media screen and (max-width: 768px){
   #navbar-toggler{
     border: none;
     outline: none;
     box-shadow: none;
  }
  #oncloud-logo {
    display: none;
  }
  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
  #landing-h4{
    margin-top: 5%;
  }
  #macbook {
    margin-top: 10%;
  }
  #learn-more-button {
    display: none;
  }
  #learn-more-button-second {
    display: inline-block!important;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  #learn-more-button-second a {
    padding-right: 25px!important;
    padding-left: 25px!important;
    letter-spacing: 2px;
    background-color: transparent;
    border-color: #1A1E3E;
    color: #1A1E3E;
    text-decoration: none;
    font-size: 20px;

  }
}


</style>
